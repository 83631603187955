import {
   Checkbox,
   Divider,
   Dropdown,
   Input,
   Select,
   Button,
   DatePicker,
   TreeSelect,
   TimePicker,
   Modal,
   notification,
   Radio,
   Pagination
} from "antd";
import React, { Fragment, useState, useEffect } from "react";
import {
   EyeInvisibleOutlined,
   EyeTwoTone,
   DownOutlined,
   CarryOutOutlined,
   CaretDownOutlined,
   CheckCircleTwoTone,
   CloseCircleTwoTone,
   ExclamationCircleOutlined,
   ArrowLeftOutlined,
   ArrowRightOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import { useTheme } from "../ThemeContext";
import { getSuperChain, getchainLicenseList } from "../../app/redux/Slices/masterDataSlices";
import { useDispatch } from "react-redux";
import { updatedDropdownList } from "../../app/utils/utilFunctions";
import '../../styles/components_scss/login.scss';
import { useNavigate } from "react-router-dom";

const { Search } = Input;
const { confirm } = Modal;

export const TextInput = (props) => {
   return (
      <div className="input-field-div" style={{ ...props?.style }}>
         <label htmlFor={props?.name} className="text-ellipsis" style={props.labelStyle} title={props.label}>
            {props.label}
            {
               props?.required && <span className="req-sign">*</span>
            }
         </label>
         <Input
            disabled={props?.disabled}
            size={props?.size}
            name={props.name}
            type={props.type || "text"}
            value={props.value}
            placeholder={props.placeholder}
            onChange={(e) => {
               props.onChange(e);
            }}
            className="input-field"
            style={{ ...props?.inputStyle }}
         />
         {props?.errorMessage && <p className="error-message">{props?.errorMessage}</p>}
      </div>
   );
};

export const TextAreaInput = (props) => {
   return (
      <div style={{ ...props?.style }}>
         <label htmlFor={props?.name}>{props.label}
            {
               props?.required && <span className="req-sign">*</span>
            }
         </label>
         <Input.TextArea
            name={props?.name}
            rows={props?.rows}
            size={props?.size}
            value={props.value}
            placeholder={props.placeholder}
            onChange={(e) => {
               props.onChange(e);
            }}
            style={{ ...props?.inputStyle }}
            className="input-field"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
         />
         {props?.errorMessage && <p className="error-message">{props?.errorMessage}</p>}
      </div>
   );
};

export const PasswordInput = (props) => {
   return (
      <div style={{ ...props?.style }}>
         <label htmlFor={props?.name}>{props.label}
            {
               props?.required && <span className="req-sign">*</span>
            }
         </label>
         <Input.Password
            name={props.name}
            size={props?.size}
            value={props.value}
            placeholder={props.placeholder}
            onChange={(e) => {
               props.onChange(e);
            }}
            style={{ ...props?.inputStyle }}
            className="input-field"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
         />
         {props?.errorMessage && <p className="error-message">{props?.errorMessage}</p>}
      </div>
   );
};

export const NumberInput = (props) => {
   return (
      <div style={{ ...props?.style }}>
         <label htmlFor={props?.name}>{props.label}
            {
               props?.required && <span className="req-sign">*</span>
            }
         </label>
         <Input
            name={props.name}
            disabled={props?.disabled}
            size={props?.size}
            type={"number"}
            value={props.value}
            placeholder={props.placeholder}
            onChange={(e) => {
               props.onChange(e);
            }}
            className="input-field"
            style={{ ...props?.inputStyle }}
         />
         {props?.errorMessage && <p className="error-message">{props?.errorMessage}</p>}
      </div>
   );
};

export const SelectCountryCode = (props) => {
   const Option = Select.Option;
   return (
      <div style={{ display: "flex", flexDirection: "column", gap: "0.7rem", ...props.style }}>
         <label>{props?.label}
            {
               props?.required && <span className="req-sign">*</span>
            }
         </label>
         <Select
            size={props?.size}
            showSearch
            value={props?.value}
            placeholder={props?.placeholder ? props?.placeholder : "select"}
            filterOption={(inputValue, option) => (option?.label ?? '').includes(inputValue)}
            onChange={(e) => props.onChange(e)}
            style={{ marginBottom: "1rem", ...props?.inputStyle }}
         >
            {props?.options?.map((ele) => {
               return (
                  <Option style={{ height: "40px" }} key={ele?.val} value={ele?.value} label={ele?.label}>
                     {ele?.label}
                  </Option>
               );
            })}
         </Select>
         {props?.errorMessage && <p className="error-message">{props?.errorMessage}</p>}
      </div>
   );
};

export const SubmitButton = (props) => {
   return (
      <>
         <button
            type="submit"
            className="form-submit-btn"
            onClick={() => {
               if (props.onClick instanceof Function) props.onClick();
            }}
            style={{ ...props?.style }}
         >
            {props.text}
         </button>
      </>
   );
};

export const CancelButton = (props) => {
   return (
      <>
         <button
            type="reset"
            className="cancel-btn"
            style={{ ...props?.style }}
            onClick={() => props?.onClick()}
         >
            {props.text}
         </button>
      </>
   );
};

export const TableFilter = (props) => {
   const { Columns, Data, setData } = props;
   const { theme } = useTheme();

   const [tableData, setTableData] = useState({
      filterTable: null,
      columns: Columns,
      baseData: Data,
   });
   const [value, setValue] = useState("");
   const [debouncedValue, setDebouncedValue] = useState('');

   useEffect(() => {
      if (!value && !tableData.filterTable) return;
      const debounceTimer = setTimeout(() => {
         search(value);
         setDebouncedValue(value);
      }, 500);

      return () => {
         clearTimeout(debounceTimer);
      };
   }, [value])

   const search = (value) => {
      const filterTableData = Data?.filter((ele) =>
         Object.keys(ele).some((k) => String(ele[k])?.toLowerCase()?.includes(value?.toLowerCase()))
      );
      setTableData({ filterTable: filterTableData });
      setData(filterTableData);
   };

   return (
      <Input.Search
         size={props?.size}
         style={{ width: "100%", marginBottom: "1rem" }}
         placeholder="Search by..."
         enterButton
         // onSearch={search}
         onChange={(e) => setValue(e.target.value)}
         className="table-filter"
      />
      // </div>
   )
}

export const SearchInput = (props) => {
   const { listData, setListData, size, setSearchValue } = props;
   const [value, setValue] = useState();
   const [debouncedValue, setDebouncedValue] = useState('');
   const Navigate = useNavigate();


   useEffect(() => {
      if (value !== undefined) {
         const debounceTimer = setTimeout(() => {
            onSearch(value);
            setDebouncedValue(value);
         }, 500);

         return () => {
            clearTimeout(debounceTimer);
         };
      }
   }, [value])


   const onSearch = (value) => {
      setSearchValue(value);
      const filteredData = listData.filter((ele) =>
         ele?.hidden !== true && Object.keys(ele).some((k) => String(ele[k])?.toLowerCase()?.includes(value?.toLowerCase()))
      );
      setListData(filteredData);
   };

   return (
      <>
         <Search
            size={size}
            placeholder={props?.placeholder || "input search text"}
            onChange={(e) => setValue(e.target.value)}
            style={{
               width: 350,
            }}
         />
         <div className="navbar-search-dropdown" style={{ display: value && props?.placeholder ? 'block' : "none" }}>
            {
               listData?.length > 0 ?
                  listData?.map((ele) => <p onClick={() => (Navigate(ele?.id), setValue(null))}>{ele?.label}</p>)
                  :
                  <p>No data found</p>
            }
         </div>
      </>
   );
};


export const SelectDropdown = (props) => {
   const Option = Select.Option;
   return (
      <div style={{ display: "flex", flexDirection: "column", ...props?.style }}>
         <label style={{ marginBottom: "0.5rem" }}>{props?.label}
            {
               props?.required && <span className="req-sign">*</span>
            }
         </label>
         <Select
            allowClear
            loading={props?.loading}
            size={props?.size}
            showSearch
            placeholder={props?.placeholder}
            value={props?.value}
            defaultValue={props?.defaultValue}
            disabled={props?.disabled}
            filterOption={(inputValue, option) => {
               const label = typeof option?.children === 'string' ? option.children.toLowerCase() : '';
               return label.includes(inputValue.toLowerCase()) || option?.key?.toLowerCase().includes(inputValue.toLowerCase());
           }}
            onChange={(e, option) => {
               props.onChange(e, option);
            }}
            style={{ ...props?.inputStyle }}
         >
            {props?.options?.map((ele, idx) => {
               return <Option key={ele?.label + ele?.value} value={ele?.value}>{ele?.label}</Option>;
            })}
         </Select>
         {props?.errorMessage && <p className="error-message">{props?.errorMessage}</p>}
      </div>
   );
};

export const MultiSelectDropdown = (props) => {
   const { options, handleMultiSelect, selectAll, multiSelectList, setAllText } = props;
   const listValues = options?.map((ele) => ele?.value);
   const [listData, setListData] = useState(options);
   const [allValue, setAllValue] = useState(false);
   useEffect(() => {
      if (allValue && multiSelectList.length != 0) {
         selectAll([]);
      }
   }, [allValue]);

   return (
      <Dropdown
         trigger={["click"]}
         dropdownRender={() => (
            <div
               style={{
                  background: "#fff",
                  zIndex: "2",
                  padding: "1rem",
                  height: "15rem",
                  overflow: "scroll",
                  borderRadius: "3px",
               }}
            // className={theme.theme === "dark" ? "dark-theme-dropdown" : "light-theme-dropdown"}
            >
               <SearchInput listData={options} setListData={setListData} size={"medium"} />
               <Divider />
               <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                  <Checkbox
                     checked={multiSelectList.length === options?.length && !allValue}
                     onChange={() => selectAll(listValues)}
                     disabled={allValue}
                  >
                     Select All
                  </Checkbox>
                  <Checkbox onChange={() => (setAllValue(!allValue), setAllText(!allValue))}>
                     All Values
                  </Checkbox>
                  {listData?.map((ele) => {
                     return (
                        <Checkbox
                           checked={multiSelectList?.includes(ele?.value)}
                           onChange={() => handleMultiSelect(ele?.value)}
                           disabled={allValue}
                        >
                           {ele?.label}
                        </Checkbox>
                     );
                  })}
               </div>
            </div>
         )}
         arrow
      >
         <Button
            style={{
               width: "20%",
               display: "flex",
               justifyContent: "space-between",
               alignItems: "center",
            }}
            // className={theme.theme === "dark" ? "dark-theme-input" : "light-theme-input"}
            size={props?.size}
         >
            Select <DownOutlined />
         </Button>
      </Dropdown>
   );
};

export const MultiSelect = (props) => {
   let [options, setOptions] = useState([]);
   let [maxCount, setMaxCount] = useState(0);
   console.log("---props.value----", props.value);
   useEffect(() => {
      if (props.options?.length) {
         if (props.selectAll) {
            let tempOpt = [{ label: "All", value: "ALL" }, ...props.options];
            setOptions(tempOpt);
         } else {
            setOptions(props.options);
         }
         setMaxCount(props.options.length);
      }
   }, [props.options]);
   return (
      <div
         style={{ display: "flex", flexDirection: "column", ...props?.style }}
      >
         <label style={{ marginBottom: "0.5rem" }}>{props?.label}
            {
               props?.required && <span className="req-sign">*</span>
            }
         </label>
         <Select
            mode="multiple"
            size={props?.size}
            allowClear
            style={{ width: "100%", marginBottom: "5px", ...props.inputStyle }}
            placeholder={props?.placeholder ? props.placeholder : "Please select"}
            value={props.value ? props.value : []}
            onChange={(e) => {
               console.log("---value---",e);
               if (props.selectAll) {
                  if (e.length && e.includes("ALL")) {
                     if (props.onChange instanceof Function) {
                        let allSelected = props.options && props.options.map((el) => el.value);
                        props.onChange(allSelected);
                        setMaxCount(1);
                     }
                  } else {
                     setMaxCount(props.options.length);
                     if (props.onChange instanceof Function) props.onChange(e);
                  }
               } else {
                  if (props.onChange instanceof Function) props.onChange(e);
               }
            }}
            selectControl={true}
            options={options || []}
            maxTagCount={0}
            maxCount={maxCount}
            maxTagPlaceholder={() => typeof props.value === "string" ? `${props.value.split(',').length} selected` : `${props.value.length} selected`}
            disabled={props.disabled}
            name={props.name}
         ></Select>
         {props?.errorMessage && <p className="error-message">{props?.errorMessage}</p>}
      </div>
   );
};

export const TreeSelectDropdown = (props) => {
   const { onTreeSelect } = props;
   const treeData = [
      {
         value: "parent 1",
         label: "parent 1",
         icon: <CarryOutOutlined />,
         children: [
            {
               value: "parent 1-0",
               label: "parent 1-0",
               icon: <CarryOutOutlined />,
               children: [
                  {
                     value: "leaf1",
                     label: "leaf1",
                     icon: <CarryOutOutlined />,
                  },
                  {
                     value: "leaf2",
                     label: "leaf2",
                     icon: <CarryOutOutlined />,
                  },
               ],
            },
            {
               value: "parent 1-1",
               label: "parent 1-1",
               icon: <CarryOutOutlined />,
               children: [
                  {
                     value: "sss",
                     label: "sss",
                     icon: <CarryOutOutlined />,
                  },
               ],
            },
         ],
      },
   ];

   const [selectedItems, setSelectedItems] = useState(0);
   const theme = useTheme();

   return (
      <div style={{ width: "20%" }}>
         <TreeSelect
            size={props?.size}
            treeData={treeData}
            placeholder="Please select"
            style={{ width: "100%" }}
            treeCheckable
            showCheckedStrategy="SHOW_CHILD"
            onChange={(newValue) => (onTreeSelect(newValue), setSelectedItems(newValue.length))}
            tagRender={() => <>{selectedItems} Selected</>}
            className={theme.theme === "dark" ? "dark-theme-input" : "light-theme-input"}
         />
      </div>
   );
};

export const DateRangePicker = (props) => {
   const { onRangeChange } = props;
   const { RangePicker } = DatePicker;
   const theme = useTheme();
   const rangePresets = [
      {
         label: "Last 7 Days",
         value: [dayjs().add(-7, "d"), dayjs()],
      },
      {
         label: "Last 14 Days",
         value: [dayjs().add(-14, "d"), dayjs()],
      },
      {
         label: "Last 30 Days",
         value: [dayjs().add(-30, "d"), dayjs()],
      },
      {
         label: "Last 90 Days",
         value: [dayjs().add(-90, "d"), dayjs()],
      },
   ];

   return (
      <div style={{ display: "flex", flexDirection: "column", ...props?.style }}>
         <label>{props?.label}</label>
         <RangePicker
            defaultValue={props.defaultValue}
            className={theme.theme === "dark" ? "dark-theme-input" : "light-theme-input"}
            size={props?.size}
            presets={rangePresets}
            onChange={(dates, dateStrings) => onRangeChange(dates, dateStrings)}
            style={{ ...props?.inputStyle }}
         />
      </div>
   );
};


export const DateTimePicker = (props) => {
   const [dateTime, setDateTime] = useState('');
   const { onDateChange, inputStyle, givenDate } = props;

   useEffect(() => {
      // Set the initial value to current date and time when component mounts
      if (givenDate) {
         setDateTime(givenDate)
      }
      else {
         setDateTime(dayjs().format('YYYY-MM-DDTHH:mm'));
      }
   }, []);


   return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
         <label htmlFor="datetime">Select Date and Time</label>
         <input
            style={inputStyle}
            type="datetime-local"
            id="datetime"
            name="datetime"
            value={dateTime}
            onChange={(dates) => onDateChange(dates)}
         />
      </div>
   );
};

export const Card = (props) => {
   return (
      <div
         style={{
            padding: "2rem 1rem",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            background: "#fff",
            borderRadius: "5px",
            ...props?.style,
         }}
      >
         {props?.children}
      </div>
   );
};
export const PageHeader = ({ title, description, buttons }) => {
   return (
      <div className="pageHeader">
         <div>
            <div className="pageHeaderTitle">{title}</div>
            <div className="pageHeaderDesc">{description}</div>
         </div>

         <div className="actions btn-container">
            {buttons &&
               Array.isArray(buttons) &&
               buttons.map((el, idx) => {
                  return <div key={idx * Math.random() + idx}>{el}</div>;
               })}
         </div>
      </div>
   );
};

export const DatePickerInput = (props) => {
   return (
      <div style={{ display: "flex", flexDirection: "column", ...props?.style }}>
         <label style={{ marginBottom: "0.5rem" }}>{props?.label}</label>
         <DatePicker
            onChange={(date, dateString) => props?.setDate(dateString)}
            style={{ ...props?.inputStyle }}
            size={props?.size}
            defaultValue={props?.defaultValue}
            placeholder={props?.placeholder}
            value={props?.value}
         />
      </div>
   );
};

export const TimePickerInput = (props) => {
   const format = "HH:mm";
   return (
      <div style={{ display: "flex", flexDirection: "column", ...props?.style }}>
         <label style={{ marginBottom: "0.5rem" }}>{props?.label}</label>
         <TimePicker format={format} onChange={(time, timeString) => props?.setTime(timeString)} size={props?.size} />
      </div>
   );
};

export const ModalComponent = (props) => {
   return (
      <Modal
         title={<div style={{ fontSize: "1.2rem", ...props?.titleStyle }}>{props?.title}</div>}
         open={props?.isModalOpen}
         onOk={props?.handleOk}
         onCancel={props.handleCancel ? props.handleCancel : () => { }}
         okText={props.okText ? props.okText : "Save"}
         width={props?.width}
         style={props?.style}
         className="modal-component"
         footer={
            props.customFooter
               ? props.customFooter
               : [
                  <CancelButton
                     text={props.cancelText ? props.cancelText : "Cancel"}
                     onClick={props.handleCancel ? props.handleCancel : () => { }}
                     style={{ width: "max-content", marginRight: "1rem" }}
                  />,
                  <button
                     type="submit"
                     form={props?.formID} //this id is for formik , so that onClick of this , it trigger the form which ever we want.
                     className="form-submit-btn"
                     onClick={props.onOk ? props.onOk : () => { }}
                     style={{ width: "max-content", ...props?.okBtnStyle }}
                  >
                     {props.okText ? props.okText : "Save"}
                  </button>,
               ]
         }
      >
         <div>{props?.children}</div>
      </Modal>
   );
};

export const showConfirmationModal = ({ title, content, handleOk, handleCancel }) => {
   confirm({
      title: title,
      content: content,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: handleOk,
      onCancel: handleCancel ? handleCancel : () => { },
      className: "confirmation-modal",
   });
};

export const notifier = (type, message) => {
   type === 'success' ?
      notification.success({
         message: 'Success',
         description: message,
         placement: 'topRight',
         duration: 3,
         icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
         className: 'success-notifier'
      })
      :
      notification.error({
         message: 'Error',
         description: message,
         placement: 'topRight',
         duration: 2,
         icon: <CloseCircleTwoTone twoToneColor="red" />,
         className: 'error-notifier'
      });
}

export const RadioComponent = (props) => {
   return (
      <div style={{ display: "flex", flexDirection: "column", marginTop: "1rem", ...props?.style }}>
         <label style={{ marginBottom: "0.5rem", ...props?.labelStyle }}>{props?.label}</label>
         <Radio.Group onChange={props?.onChange} value={props?.value} style={{ display: "flex", gap: "5rem" }}>
            {
               props?.inputs?.map((ele) => {
                  return (
                     <div style={ele?.image && { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        {ele?.image &&
                           <div style={{ height: "4rem", width: "4rem", borderRadius: "50%", backgroundColor: "#dadada", display: "flex", justifyContent: "center", alignItems: "center" }}>{ele?.image}</div>
                        }
                        <Radio value={ele?.value} name={ele?.label} style={ele?.image && { marginTop: "1rem" }}>{ele?.label}</Radio>
                     </div>
                  )
               }
               )
            }

         </Radio.Group>
      </div>
   )
}

export const Tab = (props) => {
   const [activeTabs, setActivTabs] = useState(0);
   const { data } = props

   return (
      <>
         <div style={{ borderBottom: '1px solid #efefef', display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", justifyContent: "space-between", width: "60%", position: "relative" }}>
               {
                  data &&
                  data?.map((ele, idx) => {
                     return (
                        <p onClick={() => setActivTabs(idx)} style={activeTabs === idx ? { color: "#2a6ab4", fontWeight: "600", cursor: "pointer" } : { cursor: "pointer" }}>{ele?.heading}</p>
                     )
                  })
               }
               {/* <div style={{
                  position: "absolute",
                  bottom: 0,
                  height: '4px',
                  transition: "0.3s ease-in-out",
                  left: `${activeTabs * (40 / data.length)}%`,
                  width: `${50 / data.length}%`,
                  borderBottom: "2px solid #2a6ab4",
               }}></div> */}
            </div>
         </div>
         <div style={{ marginTop: "2rem" }}>
            {/* {data && data[activeTabs]?.content} */}
            {
               data &&
               data[activeTabs]?.content
            }
         </div>
      </>
   )
}

export const ErrorContent = (props) => {
   const { data } = props
   const { setViewModal, updateError, setOffsetLimit, offsetLimit } = props
   return (
      <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
         {
            data?.map((ele) => {
               return (
                  <div style={{ border: "1px solid #efefef" }}>
                     <div style={{ padding: "5px 10px", borderBottom: "1px solid #efefef", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
                           {ele?.controller} -
                           {ele?.middleware}
                           <p style={ele?.status === 'UNRESOLVED' ? { color: "red", border: "1px solid red", padding: "4px", borderRadius: "4px", fontSize: '12px' } : { color: "green", border: "1px solid green", padding: "4px", borderRadius: "4px", fontSize: '12px' }}>{ele?.status}</p>
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                           <Button onClick={() => setViewModal({ status: true, data: ele })}>VIEW</Button>
                           {
                              ele?.status === 'UNRESOLVED' &&
                              <Button
                                 style={{
                                    backgroundColor: "#419645",
                                    border: "none"
                                 }}
                                 className="btn-clr-white"
                                 onClick={() => updateError('RESOLVED', ele?.errorTrackerID)}
                              >
                                 RESOLVE
                              </Button>
                           }

                        </div>
                     </div>
                     <div style={{ padding: "5px 10px" }}>
                        <div>
                           <p style={{ color: "red" }}>Error Message:</p>
                           <p>{ele?.errorMessage}</p>
                        </div>
                        <div style={{ display: "flex", gap: "5rem" }}>
                           <p>Tracker ID: <span style={{ color: "#2a6ab4" }}>{ele?.errorTrackerID}</span></p>
                           <p>Last Occurrence Time: <span style={{ color: "#2a6ab4" }}>{ele?.lastOccurenceTime}</span></p>
                           <p>Error Code: <span style={{ color: "red" }}>{ele?.errorStatusCode}</span></p>
                        </div>
                        <p style={{ color: "#949494" }}>File: {ele?.errorFile} - Line : {ele?.errorLine}</p>
                     </div>
                  </div>
               )
            })
         }
         <div style={{ display: "flex", justifyContent: "center", gap: "3rem" }}>
            <Button disabled={offsetLimit <= 0} onClick={() => setOffsetLimit((prevState) => prevState > 5 ? prevState - 5 : 0)}><ArrowLeftOutlined />Prev</Button>
            <Button disabled={data.length < 5} onClick={() => setOffsetLimit((prevState) => prevState + 5)}>Next<ArrowRightOutlined /></Button>
         </div>
      </div>
   )
}

// --------filter bar component--------
export const Filterbar = (props) => {
   const {
      superchainDropdown,
      superchainOnchange,
      brandDropdown,
      brandData,
      brandOnchange,
      toDate,
      fromDate,
      customDropdown,
      customDDOnchange,
      customDDOptions,
      customDDLabel,
      allowAllOptions,
      chainDropdown,
      chainOnchange,
      chainDefaultValue,
      tableCheckbox,
      brandValue
   } = props;


   const [dropdownData, setDropdownData] = useState();
   const [chainData, setChainData] = useState();
   const defaultOption = [{ label: "ALL", value: "ALL" }];

   const dispatch = useDispatch();
   // ---fetching dropdown data---
   useEffect(() => {
      if (superchainDropdown) {
         dispatch(getSuperChain())
            .then((res) => {
               if (res?.payload) {
                  setDropdownData(
                     updatedDropdownList({ options: res?.payload, labelName: "companyName", valueName: "thirdPartyChainID", allOption: !allowAllOptions ? false : true })
                  );
               }
            })
            .catch((err) => {
               console.log("---err---", err);
            });
      }
      if (chainDropdown) {
         dispatch(getchainLicenseList({}))
            .then((res) => {
               res?.payload && setChainData(updatedDropdownList({ options: res?.payload?.data, labelName: 'companyName', valueName: 'chainID', allOption: false }))
            })
            .catch((err) => console.log("----err----", err))
      }
   }, []);


   return (
      <div style={{ display: "flex", gap: "2rem", width: "60%" }}>
         {superchainDropdown && (
            <SelectDropdown
               label={"Select Superchain"}
               options={dropdownData ? dropdownData : defaultOption}
               size={"medium"}
               defaultValue={props?.superchainDefaultValue}
               style={{ width: "20rem" }}
               onChange={(val) => superchainOnchange(val)}
               placeholder="Select"
               inputStyle={{ marginBottom: "1rem" }}
            />
         )}
         {brandDropdown && (
            <SelectDropdown
               label={"Select Brand"}
               options={brandData ? brandData : defaultOption}
               size={"medium"}
               style={{ width: "20rem" }}
               onChange={(val) => brandOnchange(val)}
               defaultValue={props?.brandDefaultValue}
               placeholder="Select"
               inputStyle={{ marginBottom: "1rem" }}
               value={brandValue}
            />
         )}
         {
            chainDropdown && (
               <SelectDropdown
                  label={"Select Chain"}
                  options={chainData ? chainData : defaultOption}
                  size={"medium"}
                  style={{ width: "20rem" }}
                  onChange={(val) => chainOnchange(val)}
                  defaultValue={chainDefaultValue}
                  inputStyle={{ marginBottom: "1rem" }}
               />
            )
         }
         {
            customDropdown && (
               <SelectDropdown
                  label={customDDLabel}
                  options={customDDOptions}
                  placeholder={'Select source'}
                  size={"medium"}
                  style={{ width: "40%", ...props?.customDDStyle }}
                  onChange={(val) => customDDOnchange(val)}
                  defaultValue={props?.brandDefaultValue}
                  inputStyle={{ marginBottom: "1rem" }}
               />
            )
         }
         {
            fromDate && (
               <DatePickerInput
                  label="From Date"
                  setDate={(date) => props?.setFromDate(date)}
                  style={{ width: "100%", marginBottom: "1rem" }}
                  value={props?.fromDateValue}
               />
            )
         }
         {
            toDate && (
               <DatePickerInput
                  label="To Date"
                  setDate={(date) => props?.setToDate(date)}
                  style={{ width: "100%", marginBottom: "1rem" }}
                  value={props?.toDateValue}
               />
            )
         }
         {
            tableCheckbox && (
               <Checkbox
                  onChange={(e) => props?.setCheckboxValue(e.target.checked)}
                  style={{ marginBottom: "0.5rem" }}
               >
                  {props?.tableCheckboxlabel}
               </Checkbox>
            )
         }
      </div>
   );
};
